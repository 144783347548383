import CheckboxInput from '../../../components/inputs/CheckboxInput.jsx';
import { capitalize, formatDate, limitChars } from '../../../utils/generalFunctions.js';
import { NavLink } from 'react-router-dom';
import { LuShare2 } from 'react-icons/lu';
import { BsThreeDots } from 'react-icons/bs';
import React, { useState } from 'react';
import { copyLinkToClipboard } from '../../../utils/common.js';
import { getDynamicTenantId } from '../../../redux/cwAPI.js';
import useOutsideClick from '../../../utils/hooks.js';

const statusColors = {
    submitted: 'bg-[#FFEED6] text-[#F59742] ',
    approved: 'bg-[#DFFFF0] text-[#2BD081] ',
    declined: 'bg-[#FFEDED] text-[#FF4848]',
    pending: 'bg-blue-100 text-blue-800',
};

const DashboardRow = ({ item, functionCall, index, deleteRow, showCheckBox = true }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to track dropdown visibility

    const handleToggle = () => {
        setDropdownOpen(!dropdownOpen); // Toggle dropdown state
    };

    const handleAction = (id, actionType) => {
        deleteRow(id, actionType); // Function to handle approve or decline actions
        setDropdownOpen(false); // Close dropdown after action
    };
    const dropdownRef = useOutsideClick(() => {
        setDropdownOpen(false); // Close dropdown when clicking outside
    });

    return (
        <tr>
            {showCheckBox && (
                <td className="flex lgCustom:hidden  p-4 font-semibold text-sm text-gray-900 whitespace-nowrap ">
                    <div className="flex items-center max-w-fit">
                        <CheckboxInput
                            label="selectAll"
                            extraClasses="hidden"
                            checked={item.checked}
                            onChange={() => functionCall(index)}
                            disabled={item.status !== 'submitted'}
                        />
                    </div>
                </td>
            )}
            {/* { data-label={headers[0,1,2,3,4,5,7]} } */}
            <td className="py-4 px-4 text-[14px] text-[#3A3939] whitespace-nowrap font-inter font-normal">
                {index + 1}
            </td>
            <td className="py-4 px-4 text-[14px] text-[#3A3939] whitespace-nowrap font-inter font-normal">
                {formatDate(item.updatedAt)}
            </td>
            <td className="py-4 px-4 text-sm text-gray-900 whitespace-nowrap">
                <span className="inline-block">{`${item.customer?.first_name || ''} ${item.customer?.last_name || ''}`}</span>
            </td>
            <td className="py-4 px-4 text-sm text-gray-900 whitespace-nowrap">
                {item.customer?.email}
            </td>
            {/*<td className='py-4 px-6 text-sm text-gray-900 whitespace-nowrap'>{item.customer?.first_name}</td>*/}
            {/* <td className='py-4 px-6 text-sm text-gray-900 whitespace-nowrap'>{item.customer?.last_name}</td> */}
            {/* <td className='py-4 px-6 text-sm text-gray-900 whitespace-nowrap'>{limitChars(item.reference_no, 6)}</td> */}
            <td className="py-4 px-4 text-[14px] text-[#7E79FA] whitespace-nowrap font-inter font-normal underline">
                {limitChars(item.waiver.name, 30)}
            </td>
            <td className="py-4 px-3 text-[14px] text-gray-900 whitespace-nowrap">
                <span
                    className={`${statusColors[item.status]} px-[14px] rounded-[10px] py-[6px]  font-inter text-[14px]`}>
                    {capitalize(item.status)}
                </span>
            </td>
            <td className="whitespace-nowrap">
                <button
                    onClick={() =>
                        copyLinkToClipboard(
                            `https://${getDynamicTenantId()}.cloudwaiver.com/template/${item?.waiver?._id}/public`
                        )
                    }
                    href=""
                    className="bg-[#F5F4FF] w-[135px] h-[28px] text-[14px] text-[#272D39] font-inter font-normal pt-1 rounded-[10px] flex justify-center gap-[10px]">
                    <LuShare2 size={20} />
                    Share Waiver
                </button>
            </td>
            <td className="hidden py-4 lgCustom:flex px-6 text-sm text-gray-900 whitespace-nowrap">
                <div className="flex items-center justify-between gap-3">
                    <div className="relative" ref={dropdownRef}>
                        <button onClick={handleToggle} className="focus:outline-none">
                            <BsThreeDots size={25} className="text-[#D7D7D7]" />
                        </button>
                        {dropdownOpen && (
                            <div className="absolute right-[-30px] top-0 mt-8 w-20 pt-2 h-28 bg-white rounded-lg shadow-lg py-1 z-50 flex flex-col justify-around items-center gap-1">
                                <NavLink to={`/submission/${item._id}/view`}>
                                    <h1 className="font-inter font-semibold text-[14px]">View</h1>
                                </NavLink>
                                <button
                                    onClick={() => handleAction(item._id, 'approved')}
                                    className={`block text-sm text-gray-700 text-left ${item.status !== 'submitted' ? 'cursor-not-allowed opacity-50' : ''}`}
                                    disabled={item.status !== 'submitted'}>
                                    <h1 className="font-inter font-semibold text-[14px]">
                                        Approve
                                    </h1>
                                </button>
                                <button
                                    onClick={() => handleAction(item._id, 'declined')}
                                    className={`block text-sm text-gray-700 text-left ${item.status !== 'submitted' ? 'cursor-not-allowed opacity-50' : ''}`}
                                    disabled={item.status !== 'submitted'}>
                                    <h1 className="font-inter font-semibold text-[14px]">
                                        Decline
                                    </h1>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </td>
            {/* <td
                className="py-4 px-10 text-sm text-gray-900 whitespace-nowrap "
                data-label=""
                style={{ marginLeft: '-1em' }}>
                <div className="relative">
                    <div className="flex custom:hidden items-center gap-4">
                        {item.status === 'submitted' && (
                            <>
                                <NavLink
                                    to={`/submission/${item._id}/view`}
                                    className="text-gray-700 hover:text-gray-400">
                                    <button className="font-inter font-semibold text-[14px]">
                                        View
                                    </button>
                                </NavLink>
                                <button
                                    onClick={() => handleAction(item._id, 'approved')}
                                    className="font-inter font-semibold text-[14px] text-gray-700 hover:text-green-600">
                                    Approve
                                </button>
                                <button
                                    onClick={() => handleAction(item._id, 'declined')}
                                    className="font-inter font-semibold text-[14px] text-gray-700 hover:text-red-600">
                                    Decline
                                </button>
                            </>
                        )}
                    </div>
                    <div className="hidden custom:flex items-center">
                        {item.status === 'submitted' && (
                            <>
                                <button
                                    onClick={handleToggle}
                                    className="focus:outline-none text-[#D7D7D7]">
                                    <BsThreeDots size={25} />
                                </button>

                                {dropdownOpen && (
                                    <div className="absolute right-[-30px] top-0 mt-8 w-20 pt-2 h-28 bg-white rounded-lg shadow-lg py-1 z-50 flex flex-col justify-around items-center gap-1">
                                        <NavLink to={`/submission/${item._id}/view`}>
                                            <h1 className="font-inter font-semibold text-[14px]">
                                                View
                                            </h1>
                                        </NavLink>
                                        <button
                                            onClick={() => handleAction(item._id, 'approved')}
                                            className="block text-sm text-gray-700 text-left">
                                            <h1 className="font-inter font-semibold text-[14px]">
                                                Approve
                                            </h1>
                                        </button>
                                        <button
                                            onClick={() => handleAction(item._id, 'declined')}
                                            className="block text-sm text-gray-700 text-left">
                                            <h1 className="font-inter font-semibold text-[14px]">
                                                Decline
                                            </h1>
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </td> */}
        </tr>
    );
};

export default DashboardRow;
