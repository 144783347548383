import React from 'react';
import './styles/Button.scss';

const FormHeader = () => {
    return (
        <div className="form-builder-header">
            <span>Build your Form</span>
        </div>
    );
};

export default FormHeader;
