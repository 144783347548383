import Button from '../components/Button';

const NotFound = () => (
    <div className="fixed inset-0 h-screen w-screen w-100 font-inter">
        <div className="bg-[#F4F3FF] p-6 text-black text-center rounded-xl shadow-[#F4F3FF] h-full flex justify-center items-center flex-col">
            <div className="text-4xl font-bold pb-3 font-chivo text-wwlOrange">Coming Soon</div>
            <div className="p-3 pb-6 font-chivo">
                Oops! The page does not exist.
                <br />
                Our team has been notified, and we are working on a resolution for you!
            </div>
            <Button
                btnText="Back to Home Page"
                btnClasses="bg-btnPr"
                fullWidth="w-fit "
                onClick={() => {
                    window.location.href = '/dashboard';
                }}
            />
        </div>
    </div>
);

export default NotFound;
