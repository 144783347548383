import PropTypes from 'prop-types';
import Heading from './Heading.jsx';
import { twMerge } from 'tailwind-merge';

const FormLayout = ({ children, handleSubmit, title, subtitle, extraClasses }) => {
    return (
        <div className="w-full h-full px-6 py-4 flex justify-center items-center">
            <div className="md:py-12 p-4 sm:px-12 flex items-center w-full">
                <div className={twMerge(`w-full sm:w-96 mx-auto ${extraClasses}`)}>
                    <div className="mb-8">
                        <img
                            src="/images/logo.png"
                            className="w-28 mx-auto block mb-4"
                            alt="Logo"
                        />
                        <Heading
                            title={title}
                            subtitle={subtitle}
                            titleClasses="text-2xl font-semibold mb-2 text-textDark font-inter"
                            center={true}
                            subTitleClasses="text-btnBg font-semibold font-inter"
                        />
                    </div>
                    <form method="" onSubmit={handleSubmit}>
                        {children}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FormLayout;

FormLayout.propTypes = {
    children: PropTypes.node,
    handleSubmit: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    extraClasses: PropTypes.string,
};
