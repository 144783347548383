import { useEffect, useState } from 'react';
import Modal from '../../components/modals/Modal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../components/Spinner.jsx';
import { selectCurrentUser } from '../../redux/user/userSlice.js';
import { getMembers } from '../../redux/user/userThunk.js';
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from '../../redux/cwAPI';
import { getAllTeams } from '../../redux/team/teamThunk';
import DashboardReport from '../../components/Dashboard/DashboardReport.jsx';

const Analytics = () => {
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(null);
    const [dashboardAnalytics, setDashboardAnalytics] = useState({});

    function handleSubmit(name) {
        if (name === 'cancel') {
            setOpenModal(false);
            setError(null);
            return;
        }
        if (name.trim() === '') {
            setError('Name is required.');
            return;
        }
        setLoading(true);
        setOpenModal(false);
        postRequest(`/waivers`, { name })
            .then((r) => navigate(`/templates/${r.data._id}/builder`))
            .catch((e) => setError(e.response.data.message))
            .finally(() => {
                setOpenModal(false);
                setLoading(false);
            });
    }

    useEffect(() => {
        getUserTeams().then(() => {});
        // eslint-disable-next-line
    }, [currentUser]);

    async function getUserTeams() {
        if (currentUser) {
            setLoading(true);
            const { data } = await getRequest('/dashboard');
            setDashboardAnalytics(data);
            dispatch(getMembers(currentUser?._id));
            dispatch(getAllTeams());
            setLoading(false);
        }
    }

    return (
        <div className="flex flex-col gap-4">
            <DashboardReport analytics={dashboardAnalytics} />
            <Modal open={openModal} functionCall={handleSubmit} error={error} />
            {loading && <Spinner />}
        </div>
    );
};

export default Analytics;
