import React from 'react';
import './styles/Button.scss';
const ButtonsFormBuilder = ({ saveData, handlePreview, waiverStatus }) => {
    return (
        <>
            <div className="Button-Div-Row">
                <div onClick={saveData} className="Button-div1">
                    <button>Save Draft</button>
                </div>
                <div className="Button-div2">
                    <button onClick={() => handlePreview(waiverStatus)} className="btn2Span">
                        Preview
                    </button>
                    <button onClick={(e) => saveData(e, 'publish')} className="btn2Span2">
                        Publish
                    </button>
                </div>
            </div>
        </>
    );
};

export default ButtonsFormBuilder;
