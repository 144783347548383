import { useDispatch, useSelector } from 'react-redux';
import { addSelectedWaiver } from '../redux/waivers/waiverSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './DataTable.css';

const excludedStatus = ['declined', 'approved'];

const DashboardTableData = ({
    items,
    headers,
    TableRow,
    colspan = 1,
    bordered = false,
    paginationDetails,
    setSearchParam,
    selectAll,
    emptyMessage = 'Nothing here yet!',
    setSelectAll,
    setState,
    deleteRow,
    setSelectedCount = () => {},
    ...otherProps
}) => {
    const dispatch = useDispatch();
    const clear = useSelector((state) => state.waivers.selectedWaivers);
    const pathname = useLocation().pathname;
    const navigate = useNavigate();

    const handleViewRedirect = (id) => {
        navigate(`/submission/${id}/view`);
    };
    const handleAction = (action) => {
        const selectedItems = items.filter((item) => item.checked);
        const actionMap = {
            view: (item) => handleViewRedirect(item._id),
            approved: (item) => deleteRow(item._id, 'approved'),
            declined: (item) => deleteRow(item._id, 'declined'),
        };

        const executeAction = actionMap[action];

        if (executeAction) {
            selectedItems.map(executeAction);
        }
    };

    useEffect(() => {
        if (clear === 'CLEAR') {
            setState(items.map((item) => ({ ...item, checked: false })));
            setSelectAll(false);
            setSelectedCount(0);
        }

        // eslint-disable-next-line
    }, [clear]);

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1120);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 1120);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function selectRows(str) {
        //if no rows, do nothing on click
        if (items.length === 0) return setSelectAll((prev) => !prev);
        if (typeof str === 'boolean') {
            if (!items[0].status) {
                let temp = items.map((item) => ({ ...item, checked: str }));
                setSelectAll(str);
                setState(temp);
                return;
            }
            let count = 0;
            const selected = items.map((item) => {
                if (!excludedStatus.includes(item.status)) {
                    count++;
                    return {
                        ...item,
                        checked: str,
                    };
                } else return item;
            });
            setState(selected);
            setSelectAll(str);
            setSelectedCount(str ? count : 0);
            const selectedWaivers = selected.filter((item) => item.checked);
            if (selectedWaivers.length > 0) dispatch(addSelectedWaiver(selectedWaivers));
        } else {
            let newData = [...items];
            if (!items[0].status) {
                newData[str] = {
                    ...newData[str],
                    checked: !newData[str].checked,
                };
                setState(newData);
                return;
            }
            newData[str] = {
                ...newData[str],
                checked: !excludedStatus.includes(newData[str].status)
                    ? !newData[str].checked
                    : false,
            };
            let isAnySelected = newData.filter((item) => item.checked);
            if (!isAnySelected.length) {
                setSelectAll(false);
                setSelectedCount(0);
            } else {
                setSelectedCount(isAnySelected.length);
            }

            if (isAnySelected.length > 0) dispatch(addSelectedWaiver(isAnySelected));

            setState(newData);
        }
    }

    return (
        <>
            <div className={`flex flex-col overflow-x-auto sm:rounded-lg py-2 font-inter`}>
                {
                    <div className="flex lgCustom:hidden justify-center mb-4 ms:gap-1 gap-4 sm:gap-10 md:gap-20">
                        <button
                            onClick={() => handleAction('approved')}
                            className="bg-[#DFFFF0] text-[#2BD081] py-[6px] px-5 rounded-[10px] text-[14px] font-inter transition-colors duration-300 hover:bg-[#B0F0D5] hover:text-[#1A9C6C]">
                            Approve
                        </button>
                        <button
                            onClick={() => handleAction('declined')}
                            className="bg-[#FFEDED] text-[#FF4848] py-[6px] px-5 rounded-[10px] text-[14px] font-inter transition-colors duration-300 hover:bg-[#FFCCCC] hover:text-[#D13B3B]">
                            Reject
                        </button>
                        <button
                            onClick={() => handleAction('view')}
                            className="bg-[#CCE5FF] text-[#004085] py-[6px] px-5 rounded-[10px] text-[14px] font-inter transition-colors duration-300 hover:bg-[#99CCFF] hover:text-[#002752]">
                            View
                        </button>
                    </div>
                }
                <div className="overflow-x-auto min-h-[50vh] pb-24">
                    <div className="inline-block min-w-full align-middle">
                        <div className="">
                            <table className={`w-full text-[14px] text-left text-[#1C1B1B] `}>
                                <thead
                                    className={`text-[14px] font-normal font-inter text-[#1C1B1B] bg-ghostWhite h-12 !rounded-xl`}>
                                    <tr className="text-[#1C1B1B] font-normal">
                                        {headers?.slice(isWideScreen ? 1 : 0).map((item, index) => {
                                            return (
                                                <th
                                                    colSpan={colspan}
                                                    key={index}
                                                    scope="col"
                                                    className={`py-3 px-4  ${bordered ? 'border border-gray-300 ' : ''} whitespace-nowrap`}>
                                                    <div className="flex items-center max-w-fit">
                                                        {item}
                                                    </div>
                                                </th>
                                            );
                                        })}
                                        {colspan === 1 && pathname !== '/kiosk' && (
                                            <th
                                                key="Actions"
                                                colSpan={colspan}
                                                scope="col"
                                                className={`py-3 text-center px-4 whitespace-nowrap`}>
                                                <div className="text-[14px"></div>
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300 sm:divide-transparent bg-white font-inter">
                                    {items.length > 0 ? (
                                        items.map((item, index) => (
                                            <TableRow
                                                deleteRow={deleteRow}
                                                showCheckBox={true}
                                                key={(item._id || item.id) + index}
                                                functionCall={selectRows}
                                                item={item}
                                                {...otherProps}
                                                index={index}
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={headers.length + 2}
                                                className="py-4 pl-4 sm:pl-6 pr-3 text-[14px] text-center">
                                                {emptyMessage}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardTableData;
