import { useEffect, useRef, useState } from 'react';
import { isEmptyObject } from '../../utils/generalFunctions.js';
import Heading from '../../components/Heading.jsx';
import Input from '../../components/inputs/Input.jsx';
import Button from '../../components/Button.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/user/userThunk.js';
import { selectCurrentUser } from '../../redux/user/userSlice.js';
import toast from 'react-hot-toast';
import ProfileImageUpload from './profileImageUpload/ProfileImageUpload.jsx';
import Spinner from '../../components/Spinner.jsx';
import axios from 'axios';
import SelectInput from '../../components/inputs/SelectInput.jsx';
import Header from '../../components/inputs/Header/Header.jsx';
import Section from '../../components/Section/Section.jsx';

const Profile = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const streetRef = useRef(null);
    const cityRef = useRef(null);
    const stateRef = useRef(null);
    const postalCodeRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('Please Select');
    const [countryList, setCountryList] = useState([]);
    const data = [
        {
            id: 1,
            label: 'First Name',
            value: currentUser.first_name,
            type: 'text',
            placeholder: 'John',
            ref: firstNameRef,
            class: 'w-full sm:w-1/2 md:w-1/2',
        },
        {
            id: 2,
            label: 'Last Name',
            value: currentUser.last_name,
            type: 'text',
            placeholder: 'Doe',
            ref: lastNameRef,
            class: 'w-full sm:w-1/2 md:w-1/2',
        },
        {
            id: 3,
            label: 'Email',
            value: currentUser.username,
            type: 'email',
            placeholder: 'john@gmail.com',
            class: 'w-full sm:w-1/2 md:w-1/2',
        },
        {
            id: 4,
            label: 'Country',
            options: countryList,
            state: country,
            setState: setCountry,
            class: 'w-full sm:w-1/2 md:w-1/2',
        },
        {
            id: 5,
            label: 'Street Address',
            value: currentUser.address?.street_address || '',
            type: 'text',
            placeholder: '123 Main Street',
            ref: streetRef,
            class: 'w-full sm:w-1/2 md:w-full',
        },
        {
            id: 6,
            label: 'City',
            value: currentUser.address?.city || '',
            type: 'text',
            placeholder: 'Berlin',
            ref: cityRef,
            class: 'w-full sm:w-1/2 md:w-1/3',
        },
        {
            id: 7,
            label: 'State / Province',
            value: currentUser.address?.state || '',
            type: 'text',
            placeholder: 'Baden-Württemberg',
            ref: stateRef,
            class: 'w-full sm:w-1/2 md:w-1/3',
        },
        {
            id: 8,
            label: 'Zip / Postal Code',
            value: currentUser.address?.zip || '',
            type: 'number',
            placeholder: 'Zip Code (#####)',
            class: 'w-full sm:w-1/2 md:w-1/3',
            ref: postalCodeRef,
        },
    ];

    useEffect(() => {
        if (!isEmptyObject(currentUser) && currentUser.address?.country) {
            setCountry(currentUser.address.country);
        }
    }, [currentUser]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`https://countriesnow.space/api/v0.1/countries/`)
            .then((r) => setCountryList(r.data.data))
            .catch((e) => toast.error(e.response.data.message))
            .finally(() => setLoading(false));
    }, []);

    function handleSubmit(e) {
        e?.preventDefault();
        if (country === 'Please Select') {
            toast.error('Please select a country.');
            return;
        }
        setLoading(true);
        const body = {
            first_name: firstNameRef.current.value,
            last_name: lastNameRef.current.value,
            profile_picture: currentUser.profile_picture,
            address: {
                country,
                street_address: streetRef.current.value,
                city: cityRef.current.value,
                state: stateRef.current.value,
                zip: postalCodeRef.current.value,
            },
        };
        dispatch(updateProfile({ body, _id: currentUser._id })).then(() => setLoading(false));
    }

    return (
        <>
            <Header name={' Personal Information'} />
            <Section
                Component={
                    <>
                        <ProfileImageUpload />
                        <form className="flex flex-wrap mt-7" onSubmit={handleSubmit}>
                            {data.map((item) => {
                                if (item.id === 4) {
                                    return (
                                        <div className={`px-3 mb-6 ${item.class}`} key={item.id}>
                                            <SelectInput
                                                buttonClassNames="rounded-full bg-[#F5F4FF] px-5 py-2 text-[16px]"
                                                extraClasses="grow md:grow-0 w-40 w-full  font-inter"
                                                options={item.options}
                                                setState={item.setState}
                                                state={item.state}
                                                label="Country"
                                                manualWidth="w-100"
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            className={`px-3 mb-6 font-inter ${item.class}`}
                                            key={item.id}
                                        >
                                            <Input
                                                inputClasses={`rounded-full px-4 bg-[#F5F4FF] pl-3 `}
                                                type={item.type}
                                                placeholder={item.placeholder}
                                                label={item.label}
                                                disabled={!item.ref}
                                                inputRef={item.ref}
                                                value={item.value}
                                            />
                                        </div>
                                    );
                                }
                            })}
                            <div className="flex justify-end mt-2 grow mr-3">
                                <Button btnText="Save" btnClasses=" px-6 py-2" />
                            </div>
                        </form>
                        {loading && <Spinner />}
                    </>
                }
            ></Section>
        </>
    );
};

export default Profile;
