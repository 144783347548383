import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDynamicTenantId, getRequest } from '../../../redux/cwAPI';
import Spinner from '../../../components/Spinner';

const SplashScreen = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        setLoading(true);
        getRequest(`/kiosk/public`)
            .then((r) => setResponse(r.data))
            .catch((e) => e.response.data.message)
            .finally(() => setLoading(false));
    }, [id]);

    return (
        <section className="w-full">
            <div className="mx-auto p-6 max-w-2xl lg:px-0 bg-white rounded-lg shadow-md">
                <div className="flex justify-center mb-4">
                    <img src={response?.logo} width="360px" alt="Logo" className="object-contain rounded-2xl shadow-md" />
                </div>
                <div className="font-bold text-2xl text-center mb-2">{response?.title}</div>
                <p className="text-center text-gray-700 leading-relaxed mb-1">{response?.description}</p>

                <div className="flex justify-center flex-col text-center">
                    {response?.waivers.length > 0 &&
                        response?.waivers.map((item, index) => {
                            return (
                                <Link
                                    to={`https://${getDynamicTenantId()}.cloudwaiver.com/template/${item._id}/public`}
                                    key={index}
                                    className="flex justify-center my-4 text-center rounded-full border border-cornFlower    bg-cornFlower px-4 py-2 text-base font-medium text-white shadow-md hover:bg-indigo-500 hover:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-cornFlower focus:ring-offset-1 transition-transform transform hover:scale-105 w-80 m-auto       lgCustom:w-72 lgCustom:px-4 lgCustom:py-2 custom:w-64 custom:px-3 ms:w-full ms:px-2"
                                >
                                    {item.name}
                                </Link>
                            );
                        })}
                </div>
            </div>
            {loading && <Spinner />}
        </section>
    );
};

export default SplashScreen;
