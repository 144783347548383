import { useEffect, useState } from 'react';
import Tabs from '../../../components/Tabs';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import toast from 'react-hot-toast';
import Spinner from '../../../components/Spinner';
import { selectSingleWaiver, updateWaiver } from '../../../redux/waivers/waiverSlice';
import { getSingleWaiver } from '../../../redux/waivers/waiverThunk';
import { getDynamicTenantId, patchRequest } from '../../../redux/cwAPI';
import { tabsData } from '../../../utils/generalFunctions';

const FormBuilderTemplateContainer = ({ children, isFormBuilder = false }) => {
    const dispatch = useDispatch();
    const waiver = useSelector(selectSingleWaiver);
    const [editMode, setEditMode] = useState(false);
    const [copyState, setCopyState] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const location = useLocation().pathname;

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        dispatch(getSingleWaiver(id)).finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        setTimeout(() => {
            setCopyState(false);
        }, 5000);
    }, [copyState]);

    function handleEdit(name) {
        if (name === 'cancel') {
            setEditMode(false);
            return;
        }
        setLoading(true);
        patchRequest(`/waivers/${id}`, { name })
            .then((r) => {
                toast.success('Updated Successfully');
                dispatch(updateWaiver(name));
            })
            .catch((e) => e.response.data.message)
            .finally(() => {
                setLoading(false);
                setEditMode(false);
            });
    }

    return (
        <div className="bg-lavender">
            {loading && <Spinner />}
            <Tabs
                tabs={tabsData}
                waiverLink={`https://${getDynamicTenantId()}.cloudwaiver.com/template/${waiver?._id}/public`}
            />
            {children}
        </div>
    );
};

export default FormBuilderTemplateContainer;
