import { useEffect, useRef, useState } from 'react';
import Header from '../components/layout/Header.jsx';
import SideBarMenu from '../components/layout/SideBarMenu.jsx';
import { sideBarOptions } from '../utils/generalFunctions.js';
import { useOnClickOutside, useWindowSize } from '../utils/hooks.js';
import ErrorBoundary from '../ErrorBoundary';
import { userProfile } from '../redux/user/userThunk';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../redux/user/userSlice.js';

const ProtectedRoute = ({ children }) => {
    const [openReplyMenuIndex, setOpenReplyMenuIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const [width] = useWindowSize();
    const ref = useRef(null);

    const dispatch = useDispatch();
    const { pathname } = window.location;
    const currentUser = useSelector(selectCurrentUser);

    const isTemplatePath = pathname.includes('template');
    const isPdfPath = pathname.includes('pdf');
    const isKioskPath = pathname.includes('kiosk/preview');
    const isResetPath = pathname.includes('reset-password');
    const token = localStorage.getItem('cw-access-token');
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1120);

    const handleReplyClick = (index) => {
        if (openReplyMenuIndex === index) {
            setOpenReplyMenuIndex(-1);
        } else {
            setOpenReplyMenuIndex(index);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isTemplatePath && !isPdfPath && !isKioskPath && !isResetPath) {
                dispatch(userProfile(token));
            }
        }, 21600000000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 1120);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useOnClickOutside(ref, () => {
        if (width < 786) {
            setOpen(false);
        }
    });

    const filteredSideBarOptions = isWideScreen
        ? sideBarOptions.filter((_, index) => index !== 1)
        : sideBarOptions;

    return currentUser ? (
        <div className="bg-lavender min-h-screen">
            <div ref={ref}>
                <SideBarMenu
                    handleReplyClick={handleReplyClick}
                    setHover={setHover}
                    hover={hover}
                    openReplyMenuIndex={openReplyMenuIndex}
                    open={open}
                    width={width}
                    setOpen={setOpen}
                    data={filteredSideBarOptions}
                />
            </div>
            <div
                className={`${open || hover ? 'lg:ml-60' : 'ml-[59px] xs:ml-[36px]'} transition-all duration-500 relative`}>
                <section className="mbl-pad p-5 max-w-6xl mx-auto">
                    <Header setOpen={setOpen} />
                    <ErrorBoundary>{children}</ErrorBoundary>
                </section>
            </div>
        </div>
    ) : (
        <div className="bg-lavender min-h-screen">
            <div
                className={"transition-all duration-500"}>
                <section className="p-5 max-w-6xl mx-auto">
                    <ErrorBoundary>{children}</ErrorBoundary>
                </section>
            </div>
        </div>
    );
};

export default ProtectedRoute;
