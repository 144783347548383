import Button from '../../components/Button';
import { ClipboardIcon, FolderIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import React from 'react';
import Spinner from '../../components/Spinner';
import Modal from '../../components/modals/Modal';
import { getRequest, patchRequest, postRequest } from '../../redux/cwAPI';
import TemplateRow from './components/TemplateRow';
import { addCheck } from '../../utils/generalFunctions';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { allPermissions } from '../../redux/team/teamSlice';
import SelectInput from '../../components/inputs/SelectInput';
import DataTable from '../../components/DataTable';
import Header from '../../components/inputs/Header/Header';
import Section from '../../components/Section/Section';

const options = ['all', 'draft', 'active', 'archived'];

function Template() {
    const permissions = useSelector(allPermissions);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allTemplates, setAllTemplates] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [duplicate, setDuplicate] = useState({
        btnText: 'Submit',
        title: 'New Template',
        description: '',
        label: 'Please enter your template name',
        index: null,
        error: null,
    });
    const [selectedCount, setSelectedCount] = useState(0);
    const [templateStatus, setTemplateStatus] = useState('all');

    useMemo(() => {
        setLoading(true);
        let temp =
            templateStatus === 'active'
                ? 'published&statuses=published'
                : templateStatus === 'all'
                  ? `published&statuses=draft`
                  : `${templateStatus}&statuses=${templateStatus}`;
        getRequest(`/waivers?statuses=${temp}&timestamp=${new Date().getTime()}`)
            .then((r) => setAllTemplates(addCheck(r.data)))
            .catch((e) => toast.error(e.response.data.message))
            .finally(() => setLoading(false));
        setSelectedCount(0);
    }, [templateStatus]);

    const handleSubmit = async (name, type) => {
        if (name === 'cancel') {
            setDuplicate({
                btnText: 'Submit',
                title: 'New Template',
                description: '',
                label: 'Please enter your template name',
                index: null,
                error: null,
            });
            setOpenModal(false);
            return;
        }
        if (name?.trim() === '') {
            setDuplicate((prevState) => ({
                ...prevState,
                error: 'Name is required',
            }));
            return;
        }
        setLoading(true);
        setOpenModal(false);
        let body;
        setLoading(true);
        if (type === 'Confirmation') {
            let removedIds = [];
            setAllTemplates([]);
            allTemplates.forEach((item) => {
                if (item.checked) {
                    removedIds.push(item._id);
                } else {
                    setAllTemplates((prev) => [...prev, item]);
                }
            });
            patchRequest('/waivers/update-multiple', {
                waiver_ids: removedIds,
                status: 'archived',
            })
                .then((e) => {
                    setSelectedCount(0);
                })
                .finally(() => setLoading(false));
            return;
        }
        if (type === 'Duplicate Template') {
            const templateId = allTemplates[duplicate.index]._id;
            const { data } = await getRequest(`/waivers/${templateId}`);
            body = { ...data, name };
        } else body = { name };
        postRequest(`/waivers`, body)
            .then((r) => navigate(`/templates/${r.data._id}/builder`))
            .catch((e) => toast.error(e.response.data.message))
            .finally(() => setLoading(false));
    };

    function customOpenModal(bool, index) {
        setOpenModal(true);
        setDuplicate({
            btnText: 'Duplicate Template',
            title: 'Duplicate Template',
            description: '',
            label: 'Please provide name for the duplicate template',
            index,
        });
    }

    function deleteRow(id, idx, status) {
        setLoading(true);
        let newData = [...allTemplates.slice(0, idx), ...allTemplates.slice(idx + 1)];

        patchRequest('/waivers/update-multiple', {
            waiver_ids: [id],
            status: status,
        })
            .then((e) => {
                setAllTemplates(newData);
            })
            .finally(() => setLoading(false));
    }

    function handleDelete() {
        setDuplicate({
            btnText: 'Yes, Delete',
            title: 'Confirmation',
            description: 'Are you sure you want to delete these templates?',
        });
        setOpenModal(true);
    }

    return (
        <>
            <Header name={'Waivers Templates'} />

            <Section
                Component={
                    <>
                        <div className="p-5 bg-white shadow-sm rounded-lg">
                            <div className="flex justify-between pb-6 items-center gap-4 flex-wrap grow">
                                <span className="text-sm font-semibold text-gray-600 font-inter">
                                    List of all templates you've created.
                                </span>
                                <div className="flex gap-2 items-center justify-center md:justify-end flex-wrap md:flex-nowrap mx-auto sm:mx-0">
                                    <SelectInput
                                        options={options}
                                        state={templateStatus}
                                        setState={setTemplateStatus}
                                        buttonClassNames="rounded-full bg-[#FBF9F9] px-5 py-2 text-[16px]"
                                        extraClasses="grow md:grow-0 w-40 "
                                    />
                                    {selectedCount > 0 && templateStatus !== 'archived' && (
                                        <>
                                            <Button
                                                btnText={`Archive (${selectedCount})`}
                                                type="button"
                                                onClick={handleDelete}
                                                //  buttonClassNames='rounded-lg bg-[#FBF9F9]' extraClasses='grow md:grow-0 w-36'
                                            />
                                        </>
                                    )}
                                    {permissions.includes('template_creation') && (
                                        <Button
                                            BtnIcon={ClipboardIcon}
                                            btnText="Create waivers"
                                            type="button"
                                            onClick={() => {
                                                setDuplicate({
                                                    btnText: 'Submit',
                                                    title: 'New Template',
                                                    description: '',
                                                    label: 'Please enter your template name',
                                                    index: null,
                                                    error: null,
                                                });
                                                setOpenModal(true);
                                            }}
                                            btnClasses="rounded-full  border-btnBg px-5 py-2.5"
                                            extraClasses="grow md:grow-0 w-28"
                                            iconClasses="w-4 h-4 inline-block ml-2"
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                {/* {filteredWaivers.length > 0 ?
      <DataTable headers={DashBoardHeaders} TableRow={DashboardRow} items={filteredWaivers}
        setState={setFilteredWaivers} selectAll={selectAll} setSelectAll={setSelectAll}
        deleteRow={updateSubmissionStatus} setSelectedCount={setSelectedCount}
      /> : <div className='text-center mt-4'>
        <FolderIcon className='w-40 h-40 text-gray-400 mx-auto' />
        <span className='text-gray-500 mb-10 text-base'>No Waivers Found. Get started by creating a waiver</span>
      </div>}
*/}

                                {allTemplates.length > 0 ? (
                                    <DataTable
                                        selectAll={selectAll}
                                        setSelectAll={setSelectAll}
                                        headers={['Select', 'Name', 'Total Waivers', 'Status']}
                                        TableRow={TemplateRow}
                                        items={allTemplates}
                                        colspan={templateStatus === 'archived' ? 0 : 1}
                                        setState={setAllTemplates}
                                        setSelectedCount={setSelectedCount}
                                        deleteRow={deleteRow}
                                        customOpenModal={customOpenModal}
                                    />
                                ) : (
                                    <div className="text-center mt-4">
                                        <FolderIcon className="w-40 h-40 text-gray-400 mx-auto" />
                                        <span className="text-gray-500 mb-10 text-base font-inter">
                                            No Waivers Found. Get started by creating a waiver
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                }
            ></Section>

            <Modal open={openModal} functionCall={handleSubmit} {...duplicate} />
            {loading && <Spinner />}
        </>
    );
}

export default Template;
