import { useRef, useState } from 'react';
import Input from '../../components/inputs/Input.jsx';
import Heading from '../../components/Heading.jsx';
import Button from '../../components/Button.jsx';
import { updateProfile } from '../../redux/user/userThunk.js';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/user/userSlice.js';
import Spinner from '../../components/Spinner';
import Section from '../../components/Section/Section.jsx';
import Header from '../../components/inputs/Header/Header.jsx';

const UpdatePassword = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const password = useRef();
    const confirmPassword = useRef();
    const [loading, setLoading] = useState(false);

    const data = [
        {
            id: 1,
            label: 'Password',
            value: '',
            type: 'password',
            placeholder: '********',
            ref: password,
            class: 'w-full md:w-1/2',
        },
        {
            id: 2,
            label: 'Confirm Password',
            value: '',
            type: 'password',
            placeholder: '********',
            ref: confirmPassword,
            class: 'w-full md:w-1/2',
        },
    ];

    async function handleSubmit(e) {
        e.preventDefault();
        if (password.current.value !== confirmPassword.current.value) {
            toast.error("Passwords don't match");
            return;
        }
        setLoading(true);
        const body = {
            password: password.current.value,
        };
        dispatch(updateProfile({ body, _id: currentUser._id })).finally(() => setLoading(false));
    }

    return (
        <>
            <Header name={'Update Information'} />
            <Section
                Component={
                    <>
                        {/* <Heading title='Update Password' titleClasses='text-xl font-semibold'
            subtitle='Ensure all details are correct.' subTitleClasses='text-sm text-gray-900' /> */}
                        <form className="flex flex-wrap mt-5" onSubmit={handleSubmit}>
                            {data.map((item) => {
                                return (
                                    <div
                                        className={`px-3 mb-6  font-inter ${item.class}`}
                                        key={item.id}
                                    >
                                        <Input
                                            inputClasses="pl-3 rounded-full px-4 bg-[#F5F4FF] pt-4"
                                            type={item.type}
                                            placeholder={item.placeholder}
                                            label={item.label}
                                            inputRef={item.ref}
                                            value={item.value}
                                        />
                                    </div>
                                );
                            })}
                            <div className="flex justify-end mt-2 grow mr-4">
                                <Button btnText="Save" btnClasses=" px-6 py-2" />
                            </div>
                        </form>
                        {loading && <Spinner />}
                    </>
                }
            ></Section>
        </>
    );
};

export default UpdatePassword;
