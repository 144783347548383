import {getDynamicTenantId} from "../redux/cwAPI";

export const HOST = window.location.host;
export const PROTOCOL = window.location.protocol + '//';
export const URL = window.location.href;

export const getHostWithoutSubDomain = () => {
    // If url is 'https://app.cloudwaiver.com' or 'http://localhost:3000' or 'http://app.localhost:3000'
    // It will return 'cloudwaiver.com' or 'localhost:3000' or 'localhost:3000'
    const urlParts = HOST.split('.');
    if (urlParts.length === 3) {
        return urlParts[1] + '.' + urlParts[2];
    } else if (urlParts.length === 2) {
        return urlParts[1];
    } else {
        return urlParts[0];
    }
};

export const GenerateSubDomainUrl = (subDomain) => {
    return PROTOCOL + subDomain + '.' + getHostWithoutSubDomain();
};

export const HOME_ROUTE = GenerateSubDomainUrl('app');

// This will return the current route of the application with the subdomain
export const CURRENT_ROUTE = GenerateSubDomainUrl(getDynamicTenantId());