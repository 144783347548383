import { useEffect, useState } from 'react';
import { ArrowLeftIcon, CheckIcon, ClipboardIcon, PencilIcon } from '@heroicons/react/24/outline';
import Tabs from '../../../components/Tabs';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import toast from 'react-hot-toast';

import QRCodeComponent from '../../../components/QrCode';
import Modal from '../../../components/modals/Modal';
import { Button } from '@headlessui/react';
import Spinner from '../../../components/Spinner';
import { selectSingleWaiver, updateWaiver } from '../../../redux/waivers/waiverSlice';
import { getSingleWaiver } from '../../../redux/waivers/waiverThunk';
import { getDynamicTenantId, patchRequest } from '../../../redux/cwAPI';
import { limitChars, tabsData } from '../../../utils/generalFunctions';

const TemplateContainer = ({ children, isFormBuilder = false }) => {
    const dispatch = useDispatch();
    const waiver = useSelector(selectSingleWaiver);
    const [editMode, setEditMode] = useState(false);
    const [copyState, setCopyState] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const location = useLocation().pathname;

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        dispatch(getSingleWaiver(id)).finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        setTimeout(() => {
            setCopyState(false);
        }, 5000);
    }, [copyState]);

    function handleEdit(name) {
        if (name === 'cancel') {
            setEditMode(false);
            return;
        }
        setLoading(true);
        patchRequest(`/waivers/${id}`, { name })
            .then((r) => {
                toast.success('Updated Successfully');
                dispatch(updateWaiver(name));
            })
            .catch((e) => e.response.data.message)
            .finally(() => {
                setLoading(false);
                setEditMode(false);
            });
    }

    return (
        <div className="bg-lavender ">
            {/* {loading && <Spinner />} */}
            {/* <Button
        btnText="Go Back"
        onClick={() => navigate(-1)}
        BtnIcon={ArrowLeftIcon}
        iconClasses="w-4 h-4 text-gray-600"
        btnClasses="border border-bgDark text-black-900 px-5"
        fullWidth="justify-start mb-4"
      />

      <div className="px-2  flex flex-col md:flex-row md:justify-between w-full">
      <div>
          <div className="flex items-center space-x-4">
            <h2 className="font-bold text-2xl">
              {limitChars(waiver?.name, 30)}
            </h2>
            <button className="outline-none" onClick={(e) => setEditMode(true)}>
              <PencilIcon className="w-5 h-5" />
            </button>
            <Modal
              open={editMode}
              editMode={true}
              title="Edit Template"
              functionCall={handleEdit}
              value={waiver?.name}
            />
          </div>
          <span className="text-sm italic break-all">
            https://
            {`${getDynamicTenantId()}.cloudwaiver.com/template/${waiver?._id
              }/public`}
            {waiver?.status === "published" &&
              (copyState ? (
                <CheckIcon className="w-5 h-5 ml-2 inline mb-2" />
              ) : (
                <ClipboardIcon
                  className="w-5 h-5 ml-2 inline mb-2 cursor-pointer"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        `https://${getDynamicTenantId()}.cloudwaiver.com/template/${waiver?._id
                        }/public`
                      )
                      .then(() => setCopyState(true));
                  }}
                />
              ))}
          </span>
        </div>
      <QRCodeComponent
          url={`https://${getDynamicTenantId()}.cloudwaiver.com/template/${waiver?._id
            }/public`}
        />
      </div> */}
            {/* <QRCodeComponent
          url={`https://${getDynamicTenantId()}.cloudwaiver.com/template/${waiver?._id
            }/public`}
        /> */}
            <Tabs
                tabs={tabsData}
                waiverLink={`https://${getDynamicTenantId()}.cloudwaiver.com/template/${waiver?._id}/public`}
            />
            {!isFormBuilder ? (
                <div className="p-5 max-w-6x2 min-h-screen max-h-fit xl:w-[1145px] mx-auto">
                    {children}
                </div>
            ) : (
                { children }
            )}
        </div>
    );
};

export default TemplateContainer;
