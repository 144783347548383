import { useSelector } from 'react-redux';
import { allPermissions } from '../../redux/team/teamSlice';
import SubmissionTable from '../../components/SubmissionTable';
import Header from '../../components/inputs/Header/Header';

const SignedWaivers = () => {
    const permissions = useSelector(allPermissions);
    return permissions.includes(`waiver_submissions`) ? (
        <>
            <Header name={'Signed Waivers'} />
            <SubmissionTable title={'My Waivers List'} />
        </>
    ) : (
        <></>
    );
};

export default SignedWaivers;
