import React from 'react';
import { NavLink } from 'react-router-dom';

function Dropdown() {
    const Links = [
        {
            title: 'Account',
            src: '/settings/account',
        },
        {
            title: 'Password',
            src: '/settings/password',
        },
        {
            title: 'Integration',
            src: '/settings/integrations',
        },
        {
            title: 'Profile',
            src: '/settings/Profile',
        },
    ];

    return (
        <>
            <div className="absolute top-10 right-0 w-[120px] h-fit lg:w-[200px] lg:h-[150px] shadow-lg rounded-xl bg-white transition duration-1000 ease-in-out z-50">
                <ul className="flex flex-col my-3 gap-[3px]">
                    {Links.map((link, index) => (
                        <NavLink
                            to={link.src}
                            className={({ isActive }) => `
                font-inter font-normal text-[16px]
               hover:bg-[#F8F8FF] px-2 py-[2px] rounded-xl mx-1 cursor-pointer text-start ${
                   isActive ? 'bg-[#36D388] ' : ''
               }
                `}
                            key={index}
                        >
                            {link.title}
                            {/* <hr /> */}
                        </NavLink>
                    ))}
                </ul>
            </div>
        </>
    );
}

export default Dropdown;
