import Heading from '../../components/Heading';
import { Link, useNavigate } from 'react-router-dom';
import Modal from '../../components/modals/Modal';
import React, { useState } from 'react';
import Button from '../../components/Button';
import { postRequest } from '../../redux/cwAPI';
import toast from 'react-hot-toast';
import Spinner from '../../components/Spinner';
import { staticData } from '../../utils/builder';
import Header from '../../components/inputs/Header/Header';
import Section from '../../components/Section/Section';

const TemplateGallery = () => {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(name) {
        if (name === 'cancel') {
            setModal(false);
            return;
        }
        if (name.trim() === '') {
            setError('Name is required.');
            return;
        }
        setLoading(true);
        const body = {
            name,
            form_data: staticData,
        };
        setLoading(true);
        postRequest(`/waivers`, body)
            .then((r) => navigate(`/templates/${r.data._id}/builder`))
            .catch((e) => toast.error(e.response.data.message))
            .finally(() => {
                setModal(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Header name={'Template Gallery'} />
            <Section
                Component={
                    <>
                        <div className="min-w-0 flex-1">
                            <p className="text-lg font-medium text-gray-900 font-inter">
                                FWC Attestation Form
                            </p>
                        </div>
                        <div className="flex mt-4 gap-4 flex-wrap">
                            <Link
                                to="/admin/preview"
                                className="font-Urbanist transition rounded-full ease-in grow sm:grow-0 text-[15.5px] py-1 gap-2 font-semibold block sm:w-fit border text-center border-gray-300 bg-inputColor px-6 "
                            >
                                Preview
                            </Link>
                            <Button
                                btnText="Use Template"
                                onClick={(e) => setModal(true)}
                                fullWidth="sm:w-fit grow sm:grow-0"
                                btnClasses=" px-4 py-2 grow sm:grow-0"
                            />
                        </div>
                        <Modal
                            open={modal}
                            functionCall={handleSubmit}
                            value="FWC Attested Form"
                            error={error}
                        />
                    </>
                }
            ></Section>
            {loading && <Spinner />}
        </>
    );
};

export default TemplateGallery;
