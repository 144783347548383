import React from 'react';
import Button from '../../Button';

const Header = ({ name }) => {
    return (
        <div className="flex justify-between items-center mb-4 mt-10">
            <h1 className="text-xl md:text-[25px] font-extrabold select-none font-Urbanist text-[#1C1B1B]">
                {name}
            </h1>
            {/* <Button
                variant="outline"
                className="flex items-center gap-[6px] bg-white rounded-3xl px-2 py-[6px]"
            >
            </Button> */}
        </div>
    );
};

export default Header;
