import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({ usage, limit }) => {
    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Usage', 'Limit'],
        colors: ['#A855F7', '#FF77D1'],
        plotOptions: {
            pie: {
                donut: {
                    size: '70%',
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        //    Make th legend true, but wat the legend inside the chart exact at the center
        legend: {
            show: true,
            position: 'bottom',
        },
    };

    const series = [usage, limit];

    return (
        <>
            <Chart options={options} series={series} type="donut" />
        </>
    );
};

export default DonutChart;
