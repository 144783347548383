'use client';
import { twMerge } from 'tailwind-merge';

const Heading = ({ title, subtitle, center, titleClasses = '', subTitleClasses = '' }) => {
    return (
        <div className={center ? 'text-center' : 'text-start'}>
            <div className={twMerge(`mb-1 font-inter ${titleClasses}`)}>{title}</div>
            <div className={twMerge(`text-base  font-inter ${subTitleClasses}`)}>{subtitle}</div>
        </div>
    );
};

export default Heading;
