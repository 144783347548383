import {createBrowserRouter, Outlet} from 'react-router-dom';
import Home from './pages/dashboard/Home.jsx';
import ProtectedRoute from './hooks/ProtectedRoute.jsx';
import Login from './pages/auth/Login';
import Template from './pages/template/Template.jsx';
import SignedWaivers from './pages/signedWaivers/SignedWaiver.jsx';
import TemplateGallery from './pages/templateGallery/TemplateGallery.jsx';
import UpdateCustomer from './pages/updateCustomer/UpdateCustomer.jsx';
import CustomerSubmissions from './pages/customerSubmission/CustomerSubmissions.jsx';
import Customer from './pages/customers/Customer.jsx';
import Kiosk from './pages/kiosk/Kiosk.jsx';
import Billing from './pages/billing/Billing.jsx';
import Management from './pages/management/Management.jsx';
import ManagementTeam from './pages/managementTeam/ManagementTeam.jsx';
import CreateTeam from './pages/createTeam/CreateTeam.jsx';
import Preview from './pages/preview/Preview.jsx';
import NotFound from './pages/NotFound.jsx';
import UpdatePassword from './pages/updatePassword/updatePassword.jsx';
import Integrations from './pages/template/components/integrations-new/Integrations.jsx';
import Configure from './pages/template/components/integrations-new/Configure.jsx';
import SubmissionView from './pages/SubmissionView/SubmissionView.jsx';
import AccountSetting from './pages/template/components/AccountSetting.jsx';
import Profile from './pages/settings/Profile.jsx';
import TemplateContainer from './pages/template/components/TemplateContainer.jsx';
import SelectDomain from './components/selectDomain/SelectDomain.jsx';
import RegisterForm from './components/register/Register.jsx';
import ForgotPassword from './components/forgotPassword/ForgotPassword.jsx';
import ResetPassword from './components/reset-password/ResetPassword.jsx';
import VerificationClient from './components/verify-account/VerificationClient.jsx';
import Overview from './pages/template/components/Overview.jsx';
import Setting from './pages/template/components/Setting.jsx';
import Integration from './pages/template/components/Integration.jsx';
import FormRender from './pages/template/components/FormRender.jsx';
import SuccessState from './pages/template/components/SuccessState.jsx';
import Submissions from './pages/template/components/Submissions.jsx';
import FormBuilder from './components/FormBuilder/FormBuilder.jsx';
import FormBuilderTemplateContainer from './pages/template/components/FormBuilderTemplateContainer.jsx';
import SplashScreen from "./pages/kiosk/components/SplashScreen";
import Analytics from './pages/dashboard/Analytics.jsx';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Login/>,
    },
    {
        path: '/register',
        element: <RegisterForm/>,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword/>,
    },
    {
        path: '/reset-password/:id',
        element: <ResetPassword/>,
    },
    {
        path: '/verify-account/:hashId/:id',
        element: <VerificationClient/>,
    },
    {
        path: '/dashboard',
        element: (
            <ProtectedRoute>
                <Home/>
            </ProtectedRoute>
        ),
    },
    {
        path: '/analytics',
        element: (
            <ProtectedRoute>
                <Analytics/>
            </ProtectedRoute>
        ),
    },
    {
        path: '/templates',
        children: [
            {
                index: true,
                element: (
                    <ProtectedRoute>
                        <Template/>
                    </ProtectedRoute>
                ),
            },
            {
                path: ':id/builder',
                element: (
                    <FormBuilderTemplateContainer>
                        <FormBuilder/>
                    </FormBuilderTemplateContainer>
                ),
            },
            {
                path: ':id/overview',
                element: (
                    <TemplateContainer>
                        <Overview/>
                    </TemplateContainer>
                ),
            },
            {
                path: ':id/setting',
                element: (
                    <TemplateContainer isParent={false}>
                        <Setting/>
                    </TemplateContainer>
                ),
            },
            {
                path: ':id/submissions',
                element: (
                    <TemplateContainer>
                        <Submissions/>
                    </TemplateContainer>
                ),
            },
            {
                path: ':id/integration',
                element: (
                    <TemplateContainer>
                        <Integration/>
                    </TemplateContainer>
                ),
            },
        ],
    },
    {
        path: '/submission/:submissionId/view',
        element: (
            <ProtectedRoute>
                <SubmissionView/>
            </ProtectedRoute>
        ),
    },
    {
        path: '/template/:id/submission',
        element: <SuccessState/>,
    },
    {
        path: '/submission/:submissionId/view',
        element: (
            <ProtectedRoute>
                <SubmissionView/>
            </ProtectedRoute>
        ),
    },
    {
        path: '/pdf/:submissionId',
        element: <SubmissionView/>,
    },
    {
        path: 'signed',
        element: (
            <ProtectedRoute>
                <SignedWaivers/>
            </ProtectedRoute>
        ),
    },
    {
        path: 'gallery',
        element: (
            <ProtectedRoute>
                <TemplateGallery/>
            </ProtectedRoute>
        ),
    },
    {
        path: '/kiosk',
        element: (
            <ProtectedRoute>
                <Outlet/>
            </ProtectedRoute>
        ),
        children: [
            {
                index: true,
                element: <Kiosk/>,
            },
            {
                path: 'preview/:id',
                element: <SplashScreen/>,
            }
        ]
    },
    {
        path: '/template/:id/public',
        element: <FormRender/>,
    },
    {
        path: '/customers',
        element: (
            <ProtectedRoute>
                <Outlet/>
            </ProtectedRoute>
        ),
        children: [
            {
                index: true,
                element: <Customer/>,
            },
            {
                path: ':id/edit',
                element: <UpdateCustomer/>,
            },
            {
                path: ':customerId',
                element: <CustomerSubmissions/>,
            },
        ],
    },
    {
        path: '/billing',
        element: (
            <ProtectedRoute>
                <Billing/>
            </ProtectedRoute>
        ),
    },
    {
        path: '/domain/select',
        element: <SelectDomain/>,
    },
    {
        path: '/management',
        element: (
            <ProtectedRoute>
                <Outlet/>
            </ProtectedRoute>
        ),
        children: [
            {
                index: true,
                element: <Management/>,
            },
            {
                path: 'team/:id',
                element: <ManagementTeam/>,
            },
            {
                path: 'team/:id/user/create',
                element: <CreateTeam/>,
            },
            {
                path: 'team/create',
                element: <ManagementTeam/>,
            },
        ],
    },
    {
        path: '/admin/preview',
        element: (
            <ProtectedRoute>
                <Preview/>
            </ProtectedRoute>
        ),
    },
    {
        path: '*',
        element: <NotFound/>,
    },
    {
        path: '/settings',
        element: (
            <ProtectedRoute>
                <Outlet/>
            </ProtectedRoute>
        ),
        children: [
            {
                path: 'account',
                element: <AccountSetting/>,
            },
            {
                path: 'integrations',
                element: <Integrations/>,
            },
            {
                path: 'password',
                element: <UpdatePassword/>,
            },
            {
                path: 'configure/:id',
                element: <Configure/>,
            },
            {
                path: 'profile',
                element: <Profile/>,
            },
        ],
    },
]);
