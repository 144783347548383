import React from 'react';

import './styles/HeaderFormBuilder.scss';

const HeaderFormBuilder = ({ setOpenModal, setEditMode, loading, waiver }) => {
    return (
        <div className="cb-wrap-header">
            <div className="cb-wrap-header-first ml-3">
                <span>{waiver?.name}</span>
                <div className="cb-wrap-header-icons mr-5">
                    <span className="cursor-pointer" onClick={() => setEditMode(true)}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.6073 1.49635C15.674 0.563067 14.1608 0.563063 13.2275 1.49635L1.94943 12.7745C1.61581 13.1081 1.3884 13.533 1.29588 13.9956L0.828212 16.3339C0.60525 17.4487 1.58814 18.4317 2.70295 18.2087L5.04127 17.741C5.50392 17.6485 5.92883 17.4211 6.26245 17.0875L17.5405 5.80937C18.4738 4.87608 18.4738 3.36293 17.5405 2.42964L16.6073 1.49635ZM14.3541 2.62293C14.6652 2.31183 15.1695 2.31183 15.4807 2.62293L16.4139 3.55622C16.7251 3.86731 16.7251 4.37169 16.4139 4.68279L14.2857 6.81101L12.2259 4.75114L14.3541 2.62293ZM11.0993 5.87772L3.07601 13.901C2.9648 14.0122 2.889 14.1539 2.85816 14.3081L2.39049 16.6464L4.72881 16.1787C4.88303 16.1479 5.02467 16.0721 5.13587 15.9609L13.1591 7.93758L11.0993 5.87772Z"
                                fill="#7E79FA"
                            />
                        </svg>
                    </span>
                    <span className="cursor-pointer" onClick={() => setOpenModal(true)}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.19665 4.97876H1.95194C1.78688 4.97876 1.62859 4.91319 1.51187 4.79648C1.39516 4.67977 1.32959 4.52147 1.32959 4.35641C1.32959 4.19135 1.39516 4.03306 1.51187 3.91634C1.62859 3.79963 1.78688 3.73406 1.95194 3.73406H6.93076V1.86576C6.93076 1.7007 6.99632 1.5424 7.11304 1.42569C7.22975 1.30898 7.38805 1.24341 7.55311 1.24341H12.5319C12.697 1.24341 12.8553 1.30898 12.972 1.42569C13.0887 1.5424 13.1543 1.7007 13.1543 1.86576V3.73406H18.1331C18.2981 3.73406 18.4564 3.79963 18.5732 3.91634C18.6899 4.03306 18.7554 4.19135 18.7554 4.35641C18.7554 4.52147 18.6899 4.67977 18.5732 4.79648C18.4564 4.91319 18.2981 4.97876 18.1331 4.97876H16.8884V18.0481C16.8884 18.2132 16.8228 18.3715 16.7061 18.4882C16.5894 18.6049 16.4311 18.6705 16.266 18.6705H3.819C3.65394 18.6705 3.49564 18.6049 3.37893 18.4882C3.26221 18.3715 3.19665 18.2132 3.19665 18.0481V4.97876ZM11.9096 3.73406V2.48936H8.17546V3.73406H11.9096ZM4.44135 17.4258H15.6437V4.97876H4.44135V17.4258ZM8.17546 14.9364C8.0104 14.9364 7.8521 14.8708 7.73539 14.7541C7.61868 14.6374 7.55311 14.4791 7.55311 14.314V8.09052C7.55311 7.92546 7.61868 7.76717 7.73539 7.65045C7.8521 7.53374 8.0104 7.46817 8.17546 7.46817C8.34052 7.46817 8.49881 7.53374 8.61553 7.65045C8.73224 7.76717 8.79781 7.92546 8.79781 8.09052V14.314C8.79781 14.4791 8.73224 14.6374 8.61553 14.7541C8.49881 14.8708 8.34052 14.9364 8.17546 14.9364ZM11.9096 14.9364C11.7445 14.9364 11.5862 14.8708 11.4695 14.7541C11.3528 14.6374 11.2872 14.4791 11.2872 14.314V8.09052C11.2872 7.92546 11.3528 7.76717 11.4695 7.65045C11.5862 7.53374 11.7445 7.46817 11.9096 7.46817C12.0746 7.46817 12.2329 7.53374 12.3496 7.65045C12.4664 7.76717 12.5319 7.92546 12.5319 8.09052V14.314C12.5319 14.4791 12.4664 14.6374 12.3496 14.7541C12.2329 14.8708 12.0746 14.9364 11.9096 14.9364Z"
                                fill="#D11B1B"
                            />
                        </svg>
                    </span>
                </div>
            </div>
            <span className="cb-wrap-drag-text ml-3">Drag the Fields to the waiver</span>
        </div>
    );
};

export default HeaderFormBuilder;
