import { Link } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/20/solid';
import { UsersIcon } from '@heroicons/react/24/outline';
import { BsThreeDots } from 'react-icons/bs';
import { useState } from 'react';
import useOutsideClick from '../../../utils/hooks';

const ManagementRow = ({ item }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to track dropdown visibility
    const Handlechnge = () => {
        setDropdownOpen(!dropdownOpen); // Toggle dropdown state
    };
    const dropdownRef = useOutsideClick(() => {
        setDropdownOpen(false); // Close dropdown when clicking outside
    });
    return (
        <tr>
            <td className="py-4 px-6 font-semibold text-sm text-gray-900 whitespace-nowrap ">
                {item.name}
            </td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap">
                {item.members.length}
            </td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap">
                <div className="flex items-center space-x-6">
                    <div className="flex items-center gap-3 relative" ref={dropdownRef}>
                        <button onClick={Handlechnge} className="focus:outline-none">
                            <BsThreeDots size={25} className="text-[#D7D7D7]" />
                        </button>

                        {dropdownOpen && (
                            <div className=" z-50 absolute top-6  right-[-20px]  w-20 h-20 bg-[#F8F8FF] px-7 rounded-lg shadow-lg  flex flex-col gap-2 justify-center item-center">
                                <Link to={`/management/team/${item._id}`}>
                                    {/* <EyeIcon className='w-5 h-5 text-iconGray' /> */}
                                    <h1 className="font-inter font-semibold  text-[14px]">View</h1>
                                </Link>
                                <button>
                                    <Link to={`/management/team/${item._id}/user/create`}>
                                        {' '}
                                        <h1 className="font-inter font-semibold  text-[14px]">
                                            Edit
                                        </h1>
                                    </Link>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default ManagementRow;
