import React from 'react';

const Section = ({ Component, className = '' }) => {
    return (
        <>
            <section className={`  p-6 space-y-6 rounded-[12px] bg-white ${className}`}>
                {Component}
            </section>
        </>
    );
};

export default Section;
