import { Link } from 'react-router-dom';
import { EyeIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { BsThreeDots } from 'react-icons/bs';
import { useState } from 'react';
import useOutsideClick from '../../../utils/hooks';

const CustomersRow = ({ item, functionCall, index }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false); // State to track dropdown visibility
    const Handlechnge = () => {
        setDropdownOpen(!dropdownOpen); // Toggle dropdown state
    };

    const dropdownRef = useOutsideClick(() => {
        setDropdownOpen(false); // Close dropdown when clicking outside
    });

    return (
        <tr>
            {/*<td className='p-4 font-semibold text-sm text-gray-900 whitespace-nowrap'>*/}
            {/*  <div className="flex items-center max-w-fit">*/}
            {/*    <CheckboxInput label='selectAll' extraClasses='hidden' checked={item.checked}*/}
            {/*      onChange={() => functionCall(index)}/>*/}
            {/*  </div>*/}
            {/*</td>*/}
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap">{item.first_name}</td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap">{item.last_name}</td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap">
                {item.customer?.email || item.email}
            </td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap">
                {item.waiver_submission_count}
            </td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap">
                <div className="flex items-center space-x-6">
                    <div className="flex items-center gap-2 relative" ref={dropdownRef}>
                        <button onClick={Handlechnge} className="focus:outline-none">
                            <BsThreeDots size={25} className="text-[#D7D7D7]" />
                        </button>

                        {dropdownOpen && (
                            <div className=" z-50 absolute top-6  right-[-20px]  w-20 h-20 bg-[#F8F8FF] px-7 rounded-lg shadow-lg  flex flex-col gap-2 justify-center item-center">
                                <Link to={`/customers/${item._id}`}>
                                    <h1 className="font-inter font-semibold text-[14px]">View</h1>
                                    {/* <abbr title='View Customer'>
                <EyeIcon className='w-5 h-5 text-iconGray'/>
              </abbr> */}
                                </Link>
                                <Link to={`/customers/${item._id}/edit`}>
                                    {/* <abbr title='Edit Customer'>
                <PencilSquareIcon className='w-5 h-5 text-iconGray'/>
              </abbr> */}
                                    <h1 className="font-inter font-semibold  text-[14px]">Edit</h1>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </td>
        </tr>
    );
};
export default CustomersRow;
