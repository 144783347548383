import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

const Button = ({
    fullWidth = '',
    btnText = '',
    btnClasses = '',
    BtnIcon = null,
    iconClasses = '',
    ...otherProps
}) => {
    return (
        <div className={twMerge(`flex justify-center items-center ${fullWidth}`)}>
            <button
                {...otherProps}
                className={twMerge(
                    `text-white bg-btnPr font-Urbanist font-extrabold transition  ease-in text-[14px] rounded-full  flex justify-center items-center px-8 ms:px-6 py-2.5 gap-2 ${btnClasses}`
                )}
            >
                {BtnIcon && (
                    <BtnIcon className={`w-5 h-5 ${iconClasses ? iconClasses : 'text-textDark'}`} />
                )}
                {btnText && btnText}
            </button>
        </div>
    );
};

export default Button;

Button.propTypes = {
    fullWidth: PropTypes.string,
    btnText: PropTypes.string,
    btnClasses: PropTypes.string,
    BtnIcon: PropTypes.element,
    iconClasses: PropTypes.string,
};
