import { ImCross } from 'react-icons/im';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { resetUser, selectCurrentUser } from '../../redux/user/userSlice.js';
import { FaBars } from 'react-icons/fa';
import './Header.css';
import { allPermissions, selectAllTeams, userPermissions } from '../../redux/team/teamSlice';
import { useEffect, useState } from 'react';
import { persistor } from '../../redux/store';
import { HOME_ROUTE } from '../../utils/url';

const SideBarMenu = ({
    data,
    openReplyMenuIndex,
    handleReplyClick,
    hover,
    setHover,
    width,
    setOpen,
}) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const currentUser = useSelector(selectCurrentUser);
    const allTeams = useSelector(selectAllTeams);
    const permissions = useSelector(allPermissions);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(''); // Default to closed state for small screens
    const [showToggleButton, setShowToggleButton] = useState(true);

    const getSidebarWidth = (windowWidth, isSidebarOpen) => {
        if (windowWidth <= 375) {
            return isSidebarOpen ? 'w-60 p-3' : 'w-9'; // Toggle sidebar open/closed
        } else if (windowWidth <= 1100) {
            return 'w-[59px] p-3'; // Sidebar width 59px
        } else {
            return 'w-60 p-3'; // Sidebar width 60
        }
    };

    useEffect(() => {
        function handleResize() {
            const newWidth = getSidebarWidth(window.innerWidth, sidebarOpen);
            setSidebarWidth(newWidth);
            setShowToggleButton(window.innerWidth <= 375);
        }

        handleResize(); // Set initial width and toggle button visibility
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [sidebarOpen]);

    useEffect(() => {
        if (allTeams && currentUser) {
            let temp = allTeams.find((item) => item.members.includes(currentUser._id));
            if (temp) {
                dispatch(
                    userPermissions([
                        ...temp?.permissions,
                        'waiver_templates',
                        'sign_out',
                        'settings',
                        'dashboard',
                    ])
                );
            }
        }
    }, [allTeams, currentUser, dispatch]);

    const handleToggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarWidth(getSidebarWidth(window.innerWidth, !sidebarOpen));
    };

    return (
        <aside
            className={`
        ${sidebarWidth}
        bg-white h-screen transition-all duration-500 fixed top-0 left-0 overflow-y-auto overflow-x-hidden scrollbar-thin
        scrollbar-thumb-transparent scrollbar-track-transparent z-20`}
        >
            {showToggleButton && (
                <button
                    onClick={handleToggleSidebar}
                    className="text-2xl p-2 mt-6 bg-btnPr rounded-full text-white ml-[2px]"
                >
                    {sidebarOpen ? <ImCross size={15} /> : <FaBars size={15} />}
                </button>
            )}

            <div className={`w-full pt-[22px] `}>
                <Link
                    className={`transition-all duration-500 
            ${sidebarWidth === 'w-60 p-3' ? 'w-28' : 'w-8'}
          `}
                    to={'/dashboard'}
                >
                    <img
                        className="w-full max-w-[105px] max-h-[70px] mx-auto mb-4"
                        alt="Loading.."
                        src="/images/logo.png"
                    />
                </Link>
            </div>

            <ul className="space-y-[14px]">
                {permissions.length > 0 &&
                    data?.map((item, index) => {
                        if (permissions.includes(item.permission)) {
                            return (
                                <li
                                    className="text-[14px] relative text-iconGray font-inter font-normal"
                                    key={index}
                                >
                                    {item.subList ? (
                                        <div>
                                            <button
                                                onClick={() => handleReplyClick(index)}
                                                className={`flex items-center h-[35px] gap-4 py-1 md:py-[14px] pl-2 mt-1 md:mt-2.5 w-full relative
                          ${
                              pathname.includes(item.url)
                                  ? 'bg-cornFlower text-white rounded-full'
                                  : 'text-darkGray'
                          }`}
                                            >
                                                <img
                                                    src={item.icon}
                                                    className={`w-5 h-5 ${pathname.includes(item.url) ? 'customImg' : ''}`}
                                                />
                                                <span
                                                    className={`${sidebarWidth === 'w-60 p-3' ? 'opacity-100' : 'opacity-0'} transition-all duration-500`}
                                                >
                                                    <p className="whitespace-nowrap">
                                                        {item.title}
                                                    </p>
                                                    <ChevronDownIcon className="w-3 h-3 absolute right-4 top-1/2 transform -translate-y-1/2" />
                                                </span>
                                            </button>
                                            <Transition
                                                show={openReplyMenuIndex === index}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                                <ul className="w-full">
                                                    {item?.subList?.map((subItem, subIndex) => {
                                                        return (
                                                            <li
                                                                key={subIndex}
                                                                className="flex relative text-[14px] font-inter font-normal"
                                                            >
                                                                <Link
                                                                    to={subItem.url}
                                                                    onClick={() => {
                                                                        if (width < 1024) {
                                                                            setOpen(false);
                                                                        }
                                                                    }}
                                                                    className={`my-2 ${
                                                                        sidebarWidth === 'w-60 p-3'
                                                                            ? 'opacity-100'
                                                                            : 'opacity-0'
                                                                    } pl-10 transition-all duration-500 flex items-center h-[25px] w-[200px] gap-6 px-2.5 py-1 md:py-[14px]
                                   ${
                                       pathname.includes(subItem.title.toLowerCase())
                                           ? 'bg-[#36D388] text-white rounded-full'
                                           : 'text-darkGray'
                                   }
                                  `}
                                                                >
                                                                    {subItem.title}
                                                                </Link>
                                                                <Link to={subItem.url}>
                                                                    <span
                                                                        className={`w-6 h-6 absolute top-[10px] left-[4px] flex items-center justify-center transition-all duration-500 rounded-full p-1 ${
                                                                            pathname.includes(
                                                                                subItem.title.toLowerCase()
                                                                            )
                                                                                ? 'bg-[#36D388] text-white '
                                                                                : 'text-gray-600'
                                                                        }`}
                                                                    >
                                                                        {subItem.title[0]}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Transition>
                                        </div>
                                    ) : (
                                        <Link
                                            to={item.url}
                                            onClick={
                                                item.url === '#'
                                                    ? () => {
                                                          setOpen(false);
                                                          localStorage.removeItem(
                                                              'cw-access-token'
                                                          );
                                                          persistor.purge();
                                                          dispatch(resetUser());
                                                          window.location.href = HOME_ROUTE;
                                                      }
                                                    : () => {
                                                          if (width < 1024) {
                                                              setOpen(false);
                                                          }
                                                      }
                                            }
                                            className={`flex items-center h-[35px] lgSidebar:w-full w-[35px] xs:ml-[1px] gap-2 pl-2 ${
                                                pathname.includes(item.url)
                                                    ? 'bg-cornFlower text-white rounded-3xl'
                                                    : 'text-darkGray'
                                            }`}
                                        >
                                            <img
                                                src={item.icon}
                                                className={`w-5 h-5 ${pathname.includes(item.url) ? 'customImg' : ''}`}
                                            />
                                            <p
                                                className={`${sidebarWidth === 'w-60 p-3' ? 'opacity-100' : 'opacity-0'} transition-all duration-500 whitespace-nowrap text-[14px] leading-6 font-normal`}
                                            >
                                                {item.title}
                                            </p>
                                        </Link>
                                    )}
                                </li>
                            );
                        } else {
                            return null;
                        }
                    })}
            </ul>
        </aside>
    );
};

export default SideBarMenu;
