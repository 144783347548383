import { twMerge } from 'tailwind-merge';
import { Link, useLocation, useParams } from 'react-router-dom';

import { IoChevronBack } from 'react-icons/io5';
import { LuShare2 } from 'react-icons/lu';
import { Button } from '@headlessui/react';
import { copyLinkToClipboard } from '../utils/common';
import { CURRENT_ROUTE } from '../utils/url';

const Tabs = ({ tabs, waiverLink }) => {
    const pathname = useLocation().pathname;
    const { id } = useParams();
    return (
        <div
            className="sticky top-0 z-[101] bg-white h-[59px] w-full flex  justify-between item-center px-1  md:px-10  font-normal  text-[14px] font-inter  border-b-2 border-gray-300 overflow-x-auto sm:overflow-x-hidden
  scrollbar-thin scrollbar-thumb-btnPr scrollbar-track-btnPr  ">
            <div
                className="flex gap-1 justify-center item-center mt-3 cursor-pointer"
                onClick={() => window.location.href = `${CURRENT_ROUTE}/templates`}>
                <div className="bg-white h-[37.91px] w-[37.91px] pt-[8px] pl-[7px] border border-1 border-[#EDEAEA]  rounded-full">
                    <IoChevronBack size={20} className="text-[#3A3939]" />
                </div>
                <div>
                    {/* <p btnText='Back' btnClasses=' py-2 px-4 ' fullWidth='w-fit' /> */}
                    <p className="pt-2 pl-2 ">Black</p>
                </div>
            </div>

            <div className="flex ">
                {tabs?.map((tab) => {
                    return (
                        <Link
                            data-current-tab={tab.name}
                            key={tab.name}
                            to={`/templates/${id}/${tab.url}`}
                            className={twMerge(` pt-1
           text-gray-500  inline-block flex items-center px-4 rounded-t-lg hover:text-gray-600 space-x-2 
           ${pathname.includes(tab.url) && 'border-b-4 border-btnPr hover:border-gray-500 text-[#272D39] hover:text-gray-600'}`)}>
                            <span data-current-tab={tab.name}>{tab.name}</span>
                        </Link>
                    );
                })}
            </div>
            <div className=" bg-[#F5F4FF] rounded-[10px] w-[145px] h-[28px] mt-4 py-1 px-3">
                <Button
                    onClick={() => copyLinkToClipboard(waiverLink)}
                    className="flex gap-[2px] md:gap-2 ">
                    <LuShare2 size={20} />
                    <h4 className="text-nowrap">Share Waivers</h4>
                </Button>
            </div>
        </div>
    );
};

export default Tabs;
