import ManagementRow from './component/ManagementRow';
import { Button } from '@headlessui/react';
import DataTable from '../../components/DataTable';
import Spinner from '../../components/Spinner';
import Modal from '../../components/modals/Modal';
import Heading from '../../components/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAllTeams } from '../../redux/team/teamSlice';
import { useEffect, useState } from 'react';
import { createTeam, getAllTeams } from '../../redux/team/teamThunk';
import Header from '../../components/inputs/Header/Header';
import Section from '../../components/Section/Section';

const Management = () => {
    const teams = useSelector(selectAllTeams) || [];
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        fetchTeams().then(() => {});
        // eslint-disable-next-line
    }, []);

    async function fetchTeams() {
        setLoading(true);
        await dispatch(getAllTeams());
        setLoading(false);
    }

    function handleCreateTeam(name, value) {
        if (name === 'cancel') {
            setOpen(false);
            return;
        }
        if (name.trim() === '') {
            setError('Name is required.');
            return;
        }
        setLoading(true);
        dispatch(
            createTeam({
                name,
                permissions: [],
            })
        )
            .unwrap()
            .then((r) => {
                setOpen(false);
                navigate(`/management/team/${r._id}`);
            })
            .catch((e) => setLoading(false))
            .finally(() => setLoading(false))
            .finally(() => setLoading(false));
    }
    return (
        <>
            <Header name={'Management'} />
            <Section
                Component={
                    <>
                        <div className="flex justify-between items-center gap-3 flex-wrap font-inter ">
                            <Heading
                                title="Team"
                                subtitle="A list of all the users in your account including their name, title, email and role."
                                subTitleClasses="text-sm text-gray-900"
                                titleClasses="font-semibold text-xl"
                            />

                            <Button
                                btnClasses="text-white font-bold bg-[#7E79FA] font-Urbanist"
                                className={`font-Urbanist bg-[#7E79FA] text-[14px] px-5 py-2 font-semibold rounded-full text-white `}
                                btnText="Create Team"
                                onClick={(e) => setOpen(true)}
                            >
                                Create Team
                            </Button>
                            {/* <button className="bg-red-900">Create Team</button> */}
                        </div>

                        <DataTable
                            TableRow={ManagementRow}
                            headers={['Name', 'Total Users', 'Action']}
                            items={teams}
                            colspan="0"
                        />
                        <Modal
                            open={open}
                            title="Create Team"
                            btnText="Create"
                            functionCall={handleCreateTeam}
                            label="Team name"
                            error={error}
                        />
                    </>
                }
            ></Section>
            {loading && <Spinner />}
        </>
    );
};

export default Management;
