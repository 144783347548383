import React, { useEffect } from 'react';
import SocialServiceCard from './components/SocialServiceCard';
import { data } from './const/services_data';
import { useDispatch, useSelector } from 'react-redux';

import Heading from '../../../../components/Heading';
import { selectCurrentUser } from '../../../../redux/user/userSlice';
import { getRequest } from '../../../../redux/cwAPI';
import {
    resetIntegrations,
    selectIntegrations,
    updateIntegrationStatus,
} from '../../../../redux/integration-new/integrationSlice';
import Header from '../../../../components/inputs/Header/Header';
import Section from '../../../../components/Section/Section';

const Integrations = () => {
    const dispatch = useDispatch();
    const integrationStatus = useSelector(selectIntegrations);
    const currentUser = useSelector(selectCurrentUser);
    useEffect(() => {
        dispatch(resetIntegrations());
        getRequest(`/integration`).then((resp) => {
            for (let item of resp.data) {
                dispatch(
                    updateIntegrationStatus({
                        actionType: item.integration_type,
                        status: item.authenticated,
                    })
                );
            }
        });
    }, [currentUser, dispatch]);
    return (
        <>
            <Header name={'Integrations'} />

            <Section
                Component={
                    <div className="bg-white rounded-md p-6 w-full font-mulish">
                        {/* <Heading title='Integrations' subTitleClasses='text-sm text-btnBg'
         titleClasses='text-xl font-semibold'/> */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6 font-inter">
                            {data.map((item) => {
                                return (
                                    <SocialServiceCard
                                        key={item.id}
                                        item={item}
                                        state={integrationStatus[item.id]}
                                    />
                                );
                            })}
                        </div>
                    </div>
                }
            ></Section>
        </>
    );
};

export default Integrations;
