// import { Button } from "@/components/ui/button"
// import { Badge } from "@/components/ui/badge"
import { Button } from '@headlessui/react';
// import { CalendarIcon } from "@heroicons/react/24/solid";
import { ResponsiveLine } from '@nivo/line';
import { IoIosArrowDown } from 'react-icons/io';
import { IoCalendarOutline } from 'react-icons/io5';
import { RangeDatePicker } from '../RangeDatePicker/RangeDatePicker';
import { useEffect, useState } from 'react';
import DonutChart from './DontChart';
import { getRequest } from '../../redux/cwAPI';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/user/userSlice';

// import { Button } from "@headlessui/react"

const DashboardReport = ({ analytics }) => {
    const currentUser = useSelector(selectCurrentUser);
    const [isDatePickerShow, setIsDatePickerShow] = useState(false);
    const [range, setRange] = useState([new Date(), new Date()]); // [startDate, endDate]
    const [dashBoardChart, setDashBoardChart] = useState([]);
    const handleChange = (item) => {
        setRange([item.selection.startDate, item.selection.endDate]);
    };

    async function getDashBoardChartReport() {
        getRequest('/submissions/count')
            .then((r) => {
                const data = r.data;
                setDashBoardChart(data);
                console.log('dashboard', dashBoardChart);
            })
            .catch((e) => {
                toast.error(e.response?.data.message || 'Something went wrong!');
            });
    }
    useEffect(() => {
        getDashBoardChartReport();
    }, [currentUser]);

    function LineChart(props) {
        return (
            <div {...props}>
                <ResponsiveLine
                    curve="cardinal"
                    // margin={{
                    //   top: 2,
                    //   bottom: 2
                    // }}
                    pointColor={{ theme: 'background' }}
                    lineWidth={4}
                    animate={true}
                    tooltipFormat={(value) => `${value} submissions`}
                    tooltip={(data) => {
                        console.log('line data', data);
                        return (
                            <>
                                <div className="bg-white px-4 py-4 rounded-[10px] shadow-lg w-[190px] h-[80px] flex flex-col gap-3 relative font-inter text-[14px] ">
                                    <div className="flex flex-col gap-1 w-full h-full">
                                        <div className="flex justify-evenly">
                                            <div className="w-[13px] h-[13px]  bg-[#36D388] mt-1 rounded-full"></div>
                                            <h1 className="  font-semibold pr-7">Submissions</h1>
                                        </div>
                                        <p className=" font-normal text-[#3A3939] pl-8">
                                            {/* {data.point.data.x} */}
                                            {data.point.data.y}
                                        </p>
                                    </div>
                                    <div className="absolute bottom-[-8px] left-1/2 transform -translate-x-1/2">
                                        <div className="w-[4px] h-[4px] border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-white">
                                            {' '}
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    }}
                    enableArea={true}
                    enableGridX={false}
                    enableGridY={false}
                    fill={[{ match: { id: 'Mobile' }, id: 'dots' }]}
                    data={[
                        {
                            id: 'Mobile',
                            data: dashBoardChart,
                        },
                    ]}
                    margin={{ top: 10, right: 20, bottom: 40, left: 40 }}
                    xScale={{
                        type: 'point',
                    }}
                    yScale={{
                        type: 'linear',
                    }}
                    axisTop={null}
                    axisRight={{
                        // add padding on right side
                        tickSize: 20,
                        tickPadding: 0,
                    }}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: -45,
                    }}
                    axisLeft={{
                        tickSize: 0,
                        tickValues: 5,
                        tickPadding: 16,
                    }}
                    colors={['#36D388']} // Green and purple  colors
                    pointSize={4}
                    useMesh={true}
                    gridYValues={6}
                    theme={{
                        tooltip: {
                            chip: {
                                borderRadius: '9999px',
                            },
                            container: {
                                fontSize: '12px',
                                textTransform: 'capitalize',
                                borderRadius: '6px',
                            },
                        },
                        grid: {
                            line: {
                                stroke: '#f3f4f6',
                            },
                        },
                    }}
                    role="application"
                />
            </div>
        );
    }
    return (
        <div className="w-full xl:max-w-[1145px]  2xl:mx-auto flex  flex-col gap-3">
            {/* <div className="flex justify-between items-center mt-6 ">
        <h1 className="text-xl md:text-2xl font-bold select-none">Dashboard</h1>
        <Button
          variant="outline"
          className="flex items-center gap-[6px] bg-white rounded-3xl px-2 py-[6px]"
        >
          <CalendarIcon className="mr-2 h-4 w-4"/>
          <IoCalendarOutline />
          <p onClick={() => setIsDatePickerShow(!isDatePickerShow)} className="text-[10px] md:text-[14px] font-normal"> 10 Nov - 25 Nov</p>
          <IoIosArrowDown size={15} />
        </Button>
        {isDatePickerShow &&
          <div className="z-50 absolute top-36 right-24">
            <RangeDatePicker range={range} handleChange={handleChange} />
          </div>
        }
      </div> */}
            <div className="w-full h-auto flex flex-col sm:flex-col md:flex-row lg:flex-row gap-2 bg-transparent rounded-2xl">
                <div className="w-full md:w-8/12 lg:w-3/5 h-auto md:h-[408px] flex flex-col border bg-white rounded-lg px-2 py-1 md:px-6 md:py-6">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <h2 className="text-[20px] font-extrabold pt-[17px] md:pt-0 mr-12 md:ml-0">
                            Reports
                        </h2>
                        <div className="flex space-x-3 flex-col md:flex-row justify-start items-start">
                            <div className="flex items-center space-x-1 ml-3 md:ml-0 mt-3 md:mt-0">
                                <span className="h-[15px] w-[15px] bg-[#36D388] rounded-full" />
                                <span className="text-[14px] md:text-[14px] font-normal text-wrap md:text-nowrap font-inter">
                                    Submissions
                                </span>
                            </div>
                        </div>
                    </div>
                    <LineChart className="w-full h-[300px] md:h-full pb-4 md:pb-4 md:py-12" />
                </div>
    
                <div className="w-full md:w-8/12 lg:w-2/5 h-auto md:h-[408px] border rounded-lg bg-white">
                    <h2 className="text-xl font-extrabold mb-4 pt-6 pl-2 md:pl-6">Analytics</h2>
                    <ul className="space-y-2">
                        <li className="flex items-center space-x-2 pl-4 md:pl-12">
                            <span className="h-3 w-3 bg-yellow-500 rounded-full" />
                            <span className=" text-[16px] md:text-base text-wrap font-inter font-normal">
                                {analytics?.customers} New Customers
                            </span>
                        </li>
                        <li className="flex items-center space-x-2 pl-4 md:pl-12">
                            <span className="h-3 w-3 bg-blue-500 rounded-full" />
                            <span className="text-[16px] md:text-base text-wrap font-inter font-normal">
                                {analytics?.templates} Total Templates
                            </span>
                        </li>
                        {/* <li className="flex items-center space-x-2 pl-4 md:pl-12 flex flex-wrap">
              <span className="h-3 w-3 bg-purple-500 rounded-full" />
              <span className="text-[12px] md:text-base">{analytics?.usage} Usage</span>
              <p className=" text-[14px] md:text-sm bg-red-100 rounded-xl px-2 py-1 text-wrap font-inter font-normal">
                2500% from last week
              </p>
              <Badge variant="destructive">+2500% from last week</Badge>
            </li> */}
                    </ul>
                    <div className="mt-[32px] md:mt-4 lg:mt-[18px] flex flex-col items-center">
                        <DonutChart
                            usage={
                                analytics?.usage === 0 || analytics?.usage === undefined
                                    ? 0
                                    : analytics?.usage
                            }
                            limit={
                                analytics?.usageLimit == 0 || analytics?.usageLimit === undefined
                                    ? 100
                                    : analytics?.usageLimit
                            }
                        />
                        {/* <div
              className="relative opacity-[92%] w-[93.3px] h-[93.3px] sm:w-[163.3px] sm:h-[163.3px] flex flex-col items-center  justify-center rounded-full border-[22px] border-[#FF6BCD] 
"
            >
              <span className="w-[60px] sm:w-[87px] h-[20px] sm:h-[44px] text-[18px] ml-[20px] sm:ml-[8px] mt-3 md:ml-0 md:mt-0 sm:text-[36px] font-extrabold  text-[#272D39] ">
                $25K
              </span>
              <span className="pt-1 text-[8px] md:text-[12px] font-normal text-center font-normal">
                Revenue
              </span>
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

// function LineChart(props) {
//   return (
//     <div {...props}>
//       <ResponsiveLine
//         curve="cardinal"
//         margin={{
//           top: 2,
//           bottom: 2
//         }}
//         pointColor={{ theme: 'background' }}
//         lineWidth={4}
//         animate={true}
//         tooltipFormat={(value) => `${value} submissions`}
//         tooltip={(data) => {
//           return (
//             <>
//               <div className="bg-white px-4 py-4 rounded-[10px] shadow-lg w-[190px] h-[135px] flex flex-col gap-3 relative font-inter text-[14px] ">
//                 <div className="flex flex-col gap-1 w-full h-full">
//                   <div className="flex justify-evenly">
//                     <div className="w-[13px] h-[13px]  bg-[#36D388] mt-1 rounded-full"></div>
//                     <h1 className="  font-semibold pr-7">Submissions</h1>
//                   </div>
//                   <p className=" font-normal text-[#3A3939] pl-8">3660</p>
//                 </div>

//                 <div className="flex flex-col gap-1 w-full h-full">
//                   <div className="flex justify-evenly">
//                     <div className="w-[13px] h-[13px] rounded-full bg-[#7E79FA] mt-[5px]"></div>
//                     <h1 className="  font-semibold mt-[2px]">Sent to Customer</h1>
//                   </div>
//                   <p className=" font-normal text-[#3A3939] pl-8 ">5489</p>
//                 </div>

//                 <div className="absolute bottom-[-8px] left-1/2 transform -translate-x-1/2">
//                   <div className="w-[4px] h-[4px] border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-white"> </div>
//                 </div>
//               </div>
//             </>
//           );
//         }}
//         enableArea={true}
//         enableGridX={false}
//         enableGridY={false}
//         fill={[{ match: { id: "Mobile" }, id: "dots" }]}
//         data={[
//           {
//             id: "Desktop",
//             data: [
//               { x: "Jan", y: 43 },
//               { x: "Feb", y: 137 },
//               { x: "Mar", y: 61 },
//               { x: "Apr", y: 145 },
//               { x: "May", y: 26 },
//               { x: "Jun", y: 154 },
//             ],
//           },
//           {
//             id: "Mobile",
//             data: [
//               { x: "Jan", y: 60 },
//               { x: "Feb", y: 48 },
//               { x: "Mar", y: 177 },
//               { x: "Apr", y: 78 },
//               { x: "May", y: 96 },
//               { x: "Jun", y: 204 },
//             ],
//           },
//         ]}
//         margin={{ top: 10, right: 10, bottom: 40, left: 40 }}
//         xScale={{
//           type: "point",
//         }}
//         yScale={{
//           type: "linear",
//         }}
//         axisTop={null}
//         axisRight={null}
//         axisBottom={{
//           tickSize: 0,
//           tickPadding: 16,
//         }}
//         axisLeft={{
//           tickSize: 0,
//           tickValues: 5,
//           tickPadding: 16,
//         }}
//         colors={["#36D388", "#8782FA"]} // Green and purple  colors
//         pointSize={6}
//         useMesh={true}
//         gridYValues={6}
//         theme={{
//           tooltip: {
//             chip: {
//               borderRadius: "9999px",
//             },
//             container: {
//               fontSize: "12px",
//               textTransform: "capitalize",
//               borderRadius: "6px",
//             },
//           },
//           grid: {
//             line: {
//               stroke: "#f3f4f6",
//             },
//           },
//         }}
//         role="application"
//       />
//     </div>
//   );
// }

export default DashboardReport;
