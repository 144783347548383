import { twMerge } from 'tailwind-merge';
import { EyeIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import PropTypes from 'prop-types';

const Input = ({
    placeholder,
    type = 'text',
    label = null,
    BtnIcon = null,
    inputRef = null,
    extraClasses = '',
    inputClasses,
    value = '',
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);

    function togglePasswordVisibility() {
        setShowPassword(!showPassword);
    }

    return (
        <>
            <div className={twMerge(`w-full font-inter ${extraClasses} `)}>
                {label && (
                    <label
                        htmlFor={label.toLowerCase()}
                        className="block text-sm mb-1.5 font-semibold text-gray-500 text-start"
                    >
                        {label}
                    </label>
                )}
                <div className="relative">
                    {BtnIcon && (
                        <BtnIcon className="pointer-events-none absolute inset-y-0 left-3 mt-px flex items-center text-btnGray w-5 h-5 transform translate-y-[calc(50%-0px)]" />
                    )}
                    {type === 'password' && (
                        <EyeIcon
                            onClick={togglePasswordVisibility}
                            className="absolute top-1/2 right-3 cursor-pointer mt-px flex items-center text-btnGray w-5 h-5 transform translate-y-[calc(-50%-1px)]"
                        />
                    )}
                    <input
                        ref={inputRef}
                        defaultValue={value}
                        id={placeholder}
                        type={showPassword ? 'text' : type}
                        name={label?.toLowerCase()}
                        required="required"
                        placeholder={placeholder}
                        className={twMerge(
                            `block w-full p-2.5 rounded-md border border-gray-300 bg-[#F8F8FF] focus:border-gray-300 focus-visible:outline-none text-sm text-gray-600 ${type === 'password' && 'pr-8'} ${inputClasses ? inputClasses : 'pl-11'}`
                        )}
                        {...props}
                    />
                </div>
            </div>
        </>
    );
};

export default Input;

Input.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    BtnIcon: PropTypes.element,
    inputRef: PropTypes.object,
    extraClasses: PropTypes.string,
    inputClasses: PropTypes.string,
    value: PropTypes.string,
};
