import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';
import { selectCurrentUser } from '../../redux/user/userSlice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConfirmationModal from '../modals/ConfirmationModal';
import { persistor } from '../../redux/store';
import Input from '../inputs/Input.jsx';
import './Header.css';

import { IoIosArrowDown } from 'react-icons/io';
import Dropdown from './Dropdown/Dropdown.jsx';
import { HOME_ROUTE } from '../../utils/url';

const Header = ({ setOpen }) => {
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [description, setDescription] = useState({
        title: '',
        desc: '',
    });
    const currentUser = useSelector(selectCurrentUser);
    const location = useLocation().pathname;

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const isAdmin = localStorage.getItem('isAdmin');
        if (currentUser && !isAdmin) {
            // incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
            const trialEnd = new Date(currentUser.trial_until);
            const currentDate = new Date(); // current date
            const isTrialExpired = trialEnd < currentDate;
            const allowedLocations = ['/billing', '/settings', '/settings/password'];
            switch (currentUser.subscription?.status) {
                case 'incomplete':
                    setDescription({
                        title: 'Incomplete Subscription',
                        desc: 'Your subscription is incomplete. Please update your payment information.',
                    });
                    break;
                case 'incomplete_expired':
                    setDescription({
                        title: 'Expired Incomplete Subscription',
                        desc: 'Your incomplete subscription has expired. Please start a new subscription.',
                    });
                    break;
                case 'canceled':
                    setDescription({
                        title: 'Canceled Subscription',
                        desc: 'Your subscription has been canceled. Please contact support for assistance.',
                    });
                    break;
                case 'unpaid':
                    setDescription({
                        title: 'Unpaid Subscription',
                        desc: 'Your subscription is unpaid. Please update your payment information.',
                    });
                    break;
                default:
                    setDescription({
                        title: 'Trial Subscription',
                        desc: 'Your trial period has expired, kindly subscribe to continue using Cloud Waiver',
                    });
                    break;
            }
            const allowedStates = ['active', 'trialing', 'past_due'];
            if (currentUser.subscription) {
                if (
                    !allowedStates.includes(currentUser.subscription?.status) &&
                    !allowedLocations.includes(location)
                )
                    setIsOpen(true);
            } else {
                if (isTrialExpired && !allowedLocations.includes(location)) setIsOpen(true);
            }
        }
    }, [currentUser, location]);

    function handleClick() {
        persistor.purge();
        localStorage.removeItem('cw-access-token');
        window.location.href = HOME_ROUTE;
    }

    const data = [
        {
            id: 1,
            func: handleClick,
            text: 'Sign out',
        },
    ];

    useEffect(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1100) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        };

        handleResize(); // Set the initial state based on the current window width

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <header
            className={`h-[40px] mb-4 text-center z-10 top-0 flex left-0 right-0 justify-between items-center`}
        >
            <div className="flex justify-between ">
                <Input
                    placeholder="Search"
                    BtnIcon={MagnifyingGlassIcon}
                    inputClasses="rounded-full pl-11 grow sm:grow-0 bg-[#FBF9F9] text-sm font-normal md:w-[299px] max-w-[299px]"
                    extraClasses="w-fit inline-block grow sm:grow-0 text-btnGray"
                    // onChange={(e) => {
                    //   setSearch(e.target.value);
                    // }}
                />
                <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} {...description} />
            </div>
            <div
                onClick={handleDropdownToggle}
                className="flex justify-center item-center gap-1 cursor-pointer"
                ref={dropdownRef}
            >
                <div className="ml-[4px]">
                    <img
                        className="h-[34px] w-[34px] ms:h-[30px] ms:w-[30px] min-w-[30px] min-h-[30px] rounded-full object-cover"
                        src={currentUser?.profile_picture}
                        alt="pic"
                    />
                </div>
                <div className="flex items-center">
                    <span className="text-[16px] ml-1 font-normal select-none font-poppins">
                        {currentUser?.first_name}
                    </span>
                    <div className="relative">
                        <IoIosArrowDown size={15} className="ml-1 cursor-pointer" />
                        {dropdownOpen && <Dropdown />}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
